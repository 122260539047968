import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import { Box, Button, List, ListItem } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { languageService } from '../service'
import './MultiLanguage.scss'
import i18n from 'i18next'
import {
  getCampaignCategories,
  getCampaignList,
  getReferralInfo
} from '../../campaign/actions'
import { getOfferCategories, getOfferList } from '../../offer/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEarningList, updatePreferredLanguage } from '../../user/actions'
import { getConfigInfoByTenant } from '../../config/actions'
import { getProviderList } from '../../provider/actions'
import { selectLanguageList } from '../../config/selectors'
import { useTracking } from 'react-tracking'
import { getUpdateCardList } from '../../updateCards/actions'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
const { REACT_APP_TENANT_ID_FOR_CLIENT } = process.env
interface MultiLanguageProps {
  isOpenLanguageSelection: boolean
  setIsOpenLanguageSelection: (isOpenLanguageSelection: boolean) => void
  isFromWelcomeScreen?: boolean
}

const MultiLanguage: FC<MultiLanguageProps> = ({
  isOpenLanguageSelection,
  setIsOpenLanguageSelection,
  isFromWelcomeScreen = false
}) => {
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const initialLanguage: string = languageService.getLanguage()
  const initialLanguageTitle: string = languageService.getLanguageTitle()
  const [selectedLanguage, setSelectedLanguage] = useState(
    initialLanguage ?? ''
  )
  const [selectedTitle, setSelectedTitle] = useState(initialLanguageTitle ?? '')
  const [isChangeLang, setIsChangeLang] = useState(false)
  const languageList: LanguageList[] = useSelector(selectLanguageList)
  const closeDialog = (): void => {
    setIsOpenLanguageSelection(false)
  }
  const languageOptionHandler = (event: any): void => {
    setSelectedLanguage(event.target.value)
    setSelectedTitle(event.target.title)
  }
  useEffect(() => {
    if (initialLanguage !== selectedLanguage) {
      setIsChangeLang(true)
    }
  }, [initialLanguage, selectedLanguage])

  const handleSave = async (): Promise<void> => {
    languageService.setLanguage(selectedLanguage, selectedTitle)
    await i18n.changeLanguage(selectedLanguage)
    if (!isFromWelcomeScreen) {
      await Promise.all([
        dispatch(updatePreferredLanguage(selectedLanguage)),
        dispatch(getConfigInfoByTenant(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getCampaignList()),
        dispatch(getCampaignCategories()),
        dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getEarningList()),
        dispatch(getOfferList()),
        dispatch(getOfferCategories()),
        dispatch(getProviderList()),
        dispatch(getUpdateCardList())
      ])
    } else {
      dispatch(getConfigInfoByTenant(REACT_APP_TENANT_ID_FOR_CLIENT))
      trackEvent({
        page: 'Language',
        action: 'Selected Language',
        payload: {
          origin: 'Welcome Screen, More Menu'
        }
      })
    }
    trackEvent({
      action: 'Selected Language',
      category: 'Language',
      payload: {
        title: 'language',
        description: selectedLanguage,
        value: isChangeLang ? selectedLanguage : 'Default'
      }
    })
    closeDialog()
  }

  const buttonDisable = (): boolean => {
    const currentLanguage: string = languageService.getLanguage()
    return currentLanguage === selectedLanguage
  }
  useEffect(() => {
    setSelectedLanguage(initialLanguage)
    setSelectedTitle(initialLanguageTitle)
  }, [initialLanguage, initialLanguageTitle])
  useEffect(() => {
    if (isOpenLanguageSelection) {
      setIsChangeLang(false)
      if (!isFromWelcomeScreen) {
        trackEvent({
          action: 'Language',
          category: AnalyticsCategory.Menu
        })
      }
    }
  }, [isOpenLanguageSelection, trackEvent, isFromWelcomeScreen])
  return (
    <BaseDrawer
      isOpen={isOpenLanguageSelection}
      onClose={closeDialog}
      customClassName='multi-language-drawer'
    >
      <Box className='multi-lang-selection'>
        <List className='multi-language-wrapper'>
          {languageList != null &&
            Object.values(languageList).map((lang, index) => (
              <ListItem
                selected={selectedLanguage === lang.LanguageCode}
                key={lang.LanguageName}
                className={`multi-language-list-item ${
                  selectedLanguage === lang.LanguageCode && isFromWelcomeScreen
                    ? 'selected'
                    : ''
                }`}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setSelectedLanguage(lang.LanguageCode)
                    setSelectedTitle(lang.LanguageName)
                  }
                }}
              >
                <label className='language-option'>
                  <span>{t([lang.LanguageName])}</span>
                  <input
                    type='checkbox'
                    key={lang.LanguageName}
                    id='checkbox'
                    value={lang.LanguageCode}
                    title={lang.LanguageName}
                    onClick={languageOptionHandler}
                  />
                  <CheckIcon
                    className={`check-icon ${
                      selectedLanguage === lang.LanguageCode
                        ? 'check-icon-show'
                        : 'check-icon-hide'
                    }`}
                  />
                </label>
              </ListItem>
            ))}
        </List>
      </Box>
      <Box className='multi-lang-save-btn'>
        <Button
          onClick={handleSave}
          disabled={buttonDisable()}
          className={`btn save-button  ${
            buttonDisable() ? 'save-button--disabled' : 'save-button--abled'
          }`}
        >
          {t`Save`}
        </Button>
      </Box>
    </BaseDrawer>
  )
}

export default MultiLanguage
