import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Skeleton } from '@material-ui/lab'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import TransactionHistoryItem from './TransactionHistoryItem/TransactionHistoryItem'
import { selectTransactionList } from '../selectors'
import { IdList } from '../../core/utils/id-list'
import {
  ackEarning,
  clearTransactionList,
  getTransactionList
} from '../actions'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_TRANSACTION_LIST } from '../actionTypes'
import { TransactionType, TransactionTypeOptions } from '../transactionType'
import { selectOfferList, selectOfferHiddenList } from '../../offer/selectors'
import { getOffersHiddenList } from '../../offer/actions'
import _ from 'lodash'
import './TransactionHistoryList.scss'
import { useTracking } from 'react-tracking'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'

const ALL_TRANSACTIONS = 'ALL_TRANSACTIONS'

interface TransactionHistoryProps {
  isOpenTransactionHistory: boolean
  setIsOpenTransactionHistory: (isOpenTransactionHistory: boolean) => void
  isFromDesktop?: boolean
}
const TransactionHistoryList: FC<TransactionHistoryProps> = ({
  isOpenTransactionHistory,
  setIsOpenTransactionHistory,
  isFromDesktop = false
}) => {
  const { t } = useTranslation()
  const dispatch: Dispatch<TransactionListAction> = useDispatch()
  const transactionList: TransactionList = useSelector(selectTransactionList)
  const isTransactionLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_TRANSACTION_LIST)
  )
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const offerList: Offer[] = useSelector(selectOfferList)
  const offerHiddenList: Offer[] = useSelector(selectOfferHiddenList)
  const [transactionLoaded, setTransactionLoaded] = useState(false)
  const [selectionType, setSelectionType] = useState(ALL_TRANSACTIONS)
  const [triggerFetch, setTriggerFetch] = useState(false)
  const { trackEvent } = useTracking()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  useEffect(() => {
    if (isOpenTransactionHistory) {
      dispatch(getTransactionList())
        .then(() => setTransactionLoaded(true))
        .catch((error: Error) => Sentry.captureException(error, {
          tags: {
            method: 'dispatch-getTransactionList'
          }
        }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTransactionHistory])
  useEffect(() => {
    if (transactionLoaded && transactionList.data.length > 0) {
      const allOffers = [...offerList, ...offerHiddenList]
      const missingOfferIDList: string[] = transactionList.data
        .filter(
          (transaction) => transaction.transactionType === TransactionType.Spend
        )
        .filter((transaction) => transaction.redemption.OfferID !== null)
        .filter((transaction) => {
          const hasOffer = _.find(allOffers, {
            OfferID: transaction.redemption.OfferID
          })
          return hasOffer === null || hasOffer === undefined
        })
        .map((transaction) => transaction.redemption.OfferID)
      if (missingOfferIDList.length > 0) {
        dispatch(getOffersHiddenList(missingOfferIDList))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionLoaded])

  useEffect(() => {
    setIsScrollable(true)
    if (triggerFetch) {
      dispatch(getTransactionList(selectionType))
        .then(() => setTransactionLoaded(true))
        .catch((error: Error) => Sentry.captureException(error, {
          tags: {
            method: 'dispatch-getTransactionlist'
          }
        }))
      setTriggerFetch(false)
    }
  }, [triggerFetch, selectionType, dispatch])

  const fetchMoreData = (): Promise<void> | void => {
    if (transactionList.links.next !== '') {
      return dispatch(
        getTransactionList(selectionType, transactionList.links.next)
      )
    } else {
      setIsScrollable(false)
    }
  }
  const closeDialog = (): void => {
    transactionList.data
      .filter(
        (transaction) =>
          transaction.transactionType === TransactionType.Earn &&
          transaction.earning.AcknowledgedAtUTC === null
      )
      .forEach((transaction) =>
        dispatch(ackEarning(transaction.earning.EarningID)).catch(
          (error: AxiosResponse) =>
            Sentry.captureException(error.data.error_description, {
              tags: {
                method: 'dispatch-ackEarning'
              }
            })
        )
      )
    setIsOpenTransactionHistory(false)
    setIsScrollable(true)
    dispatch(clearTransactionList())
  }
  const onOptionChangeHandler = (event: any): void => {
    setSelectionType(event.target.value)
    setTriggerFetch(true)
    trackEvent({
      action: 'Filter Transaction Selected',
      page: AnalyticsCategory.PTH,
      payload: { transactionType: event.target.value }
    })
  }
  const onFocus = (): void => {
    trackEvent({
      action: 'Filter Transaction',
      page: AnalyticsCategory.PTH
    })
  }

  return (
    <div style={{ marginTop: matchesMd ? 35 : 16 }} id='transaction-history-box'>
      <BaseDrawer
        isButtonAvailable
        isOpen={isOpenTransactionHistory}
        onClose={closeDialog}
        title={t`TransactionHistory`}
        isFromDesktopTransactionHistory={isFromDesktop}
      >
        <select
          aria-label='Transactions type select'
          onChange={onOptionChangeHandler}
          onFocus={onFocus}
          tabIndex={0}
        >
          {Object.keys(TransactionTypeOptions).map((key, index) => (
            <option key={index} value={key}>
              {`${t(key)}`}
            </option>
          ))}
        </select>
        <div
          id={IdList.transactionHistoryList}
          className='transaction-history-list'
        >
          {isTransactionLoaded ? (
            transactionList.data.length > 0 ? (
              <div>
                <InfiniteScroll
                  dataLength={transactionList.data.length}
                  next={fetchMoreData}
                  hasMore={isScrollable}
                  loader={
                    <Skeleton
                      variant='rect'
                      width={150}
                      classes={{ root: 'skeleton-txt--default' }}
                    />
                  }
                  scrollableTarget={IdList.transactionHistoryList}
                >
                  {transactionList.data.map((item, i) => (
                    <TransactionHistoryItem transaction={item} key={i} />
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              <div className='noTransactions'>{t`NoTransactionType`}</div>
            )
          ) : (
            Array(10)
              .fill(0)
              .map((_, i) => (
                <Skeleton
                  variant='rect'
                  classes={{ root: 'transaction-history-list--loading__item' }}
                  key={i}
                />
              ))
          )}
        </div>
      </BaseDrawer>
    </div>
  )
}
export default TransactionHistoryList
