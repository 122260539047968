import { FC } from 'react'
import error from '../../../assets/images/error-transit.png'
import './TransitErrorPage.scss'

const TranitErrorPage: FC = () => {
  return (
    <div className='error-display'>
      <div className='error-content'>
        <img src={error} alt='representing an Error' className='error-image' />
        <p className='error-title'>Oh no, bad luck!'</p>
        <p className='error-description'>
          There was a problem processing your request.
          <br />
          <br />
          Close this page and select the option to open Rewards again.
        </p>
      </div>
    </div>
  )
}

export default TranitErrorPage
