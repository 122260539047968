import { Box, Typography } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import './InviteCodeForm.scss'
import '../SignUp.scss'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { RoutePath } from '../../../core/routes/route-path'
import './InviteCodeFormMobileView.scss'
import { useSelector } from 'react-redux'
import { selectConfig } from '../../../config/selectors'
import InviteCodeForm from './InviteCodeForm'
import { getSignUpScreenImage, getSignUpWelcomeSteps } from '../../utils'
import MultiLanguage from '../../../language/MultiLanguage/MultiLanguage'
import LanguageIcon from '@material-ui/icons/Language'
import { useTranslation } from 'react-i18next'
import { languageService } from '../../../language/service'

const InviteCodeFormMobileView = ({
  customTheme,
  handleInviteCodeSubmit
}: InviteCodeFormProps): ReactElement => {
  const config: ConfigState = useSelector(selectConfig)
  const { t } = useTranslation()
  const [isOpenLanguageSelection, setIsOpenLanguageSelection] =
    useState<boolean>(false)
  const currentLanguage: string = languageService.getLanguageTitle()

  return (
    <Box className='invite-code-form-mob'>
      {config.SupportLanguage && config.IsWelcomeScreenSelector && (
        <div className='language-selector'>
          <LanguageIcon className='lang-icon' />
          <span className='current-lang'>{t([currentLanguage])}</span>
          <div
            onClick={() => setIsOpenLanguageSelection(true)}
            className='change-lang'
          >
            {t`ChangeLanguage`}
          </div>
        </div>
      )}
      <Box>
        <Link
          to={RoutePath.SignIn}
          className='back-to-sign-in no-decor'
          aria-label='back to sign in'
        >
          <ArrowBackIcon
            fontSize='default'
            className='arrow-responsive'
            aria-hidden='true'
          />
        </Link>
        <Box className='form-steps-box'>
          <Box className='welcome-box'>
            <img
              src={getSignUpScreenImage(config)}
              alt='welcome'
              aria-hidden='true'
            />
            <Typography>{config.SignUpScreenTitle}</Typography>
          </Box>
          <Box className='welcome-steps-box'>
            {getSignUpWelcomeSteps(config, {}, 'title')}
          </Box>
        </Box>
      </Box>
      <InviteCodeForm
        isInviteCodeDisabled={
          !config.WelcomeScreenConfig.IsInviteCodeDisplay ?? false
        }
        isInviteCodeMandatory={
          config.WelcomeScreenConfig.IsInviteCodeMandatory ?? false
        }
        customTheme={customTheme}
        handleInviteCodeSubmit={handleInviteCodeSubmit}
        moreInformationLink={config.WelcomeScreenConfig.MoreInformationLink}
        moreInformationLinkDescription={
          config.WelcomeScreenConfig.MoreInformationLinkDescription
        }
        termsAndConditionsUrl={
          config.WelcomeScreenConfig.TermsAndConditionsURL ?? ''
        }
        privacyPolicyUrl={config.WelcomeScreenConfig.PrivacyPolicyURL ?? ''}
        moreInformationUrl={
          config.WelcomeScreenConfig.MoreInformationLinkURL ?? ''
        }
      />
      <MultiLanguage
        isOpenLanguageSelection={isOpenLanguageSelection}
        setIsOpenLanguageSelection={setIsOpenLanguageSelection}
        isFromWelcomeScreen
      />
    </Box>
  )
}

export default InviteCodeFormMobileView
