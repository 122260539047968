import { FC, useRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { useMediaQuery } from '@react-hook/media-query'
import { Button, InputBase, Link, ButtonBase, Divider } from '@material-ui/core'
import { useTracking } from 'react-tracking'
import { breakpoints } from '../../../../core/utils/css-selectors'
import { IdList } from '../../../../core/utils/id-list'
import './RedemptionWithCode.scss'
import placeholderOffer from '../../../../../assets/images/offer/placeholder-offer.png'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { AnalyticsCategory } from '../../../../core/analytics/analyticsCategory'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'

const MAX_FIELDS_LIMIT = 3
const FIRST_INDEX = 0
const SECOND_INDEX = 1

interface RedemptionDrawerWithCodeProps {
  handleCloseRedemption: () => void
  offerItem: Offer | undefined
  redemption: Redemption | undefined
  handleOpenHistory: () => void
  handleOpenTerms: () => void
  isFullWidth?: boolean
  isCustom?: boolean
  isFromUpdatedUI?: boolean
  isFromUpdatedDesktopUI?: boolean
}

const RedemptionWithCode: FC<RedemptionDrawerWithCodeProps> = ({
  handleCloseRedemption,
  offerItem,
  redemption,
  handleOpenHistory,
  handleOpenTerms,
  isFullWidth = false,
  isCustom = false,
  isFromUpdatedUI = false,
  isFromUpdatedDesktopUI = false
}) => {
  const { t } = useTranslation()
  const textCopyRef = useRef<HTMLInputElement>()
  const textCopyRef2 = useRef<HTMLInputElement>()
  const textCopyRef3 = useRef<HTMLInputElement>()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { trackEvent } = useTracking()
  const [fields, setFields] = React.useState<null | { [key: string]: string }>(
    null
  )
  const isFromUpdatedMobileUI = !matchesMd && isFromUpdatedUI
  const redemptionClassName = isFromUpdatedUI
    ? 'redemption-drawer-new'
    : 'redemption-drawer-code'
  const isApplyCode = (couponUrl: string): boolean => couponUrl != null

  const copyButtonPressEvent = (origin: string): void => {
    trackEvent({
      action: 'Copy Code Pressed',
      page: AnalyticsCategory.Redemption,
      payload: {
        offerID: redemption?.OfferID,
        redemptionID: redemption?.TransactionID,
        origin
      }
    })
  }

  React.useEffect(() => {
    if (redemption?.FieldMappings != null) {
      const fields = JSON.parse(redemption.FieldMappings)
      setFields({ ...fields })
    }
  }, [redemption?.FieldMappings])

  const copyToClipboard = (
    ref: React.MutableRefObject<HTMLInputElement | undefined>
  ): void => {
    ref.current?.select()
    document.execCommand('copy')
  }

  const copyToClipboardFromCTA: (
    ref: React.MutableRefObject<HTMLInputElement | undefined>
  ) => void = (ref: React.MutableRefObject<HTMLInputElement | undefined>) => {
    copyButtonPressEvent('CTA')
    copyToClipboard(ref)
  }

  const copyToClipboardFromInput: (
    ref: React.MutableRefObject<HTMLInputElement | undefined>
  ) => void = (ref: React.MutableRefObject<HTMLInputElement | undefined>) => {
    copyButtonPressEvent('input')
    copyToClipboard(ref)
  }

  const applyCode = (): void => {
    trackEvent({
      action: 'Apply Code Pressed',
      page: AnalyticsCategory.Redemption,
      payload: {
        offerID: redemption?.OfferID
      }
    })
    if (redemption != null && redemption.couponUrl !== '') {
      window.open(redemption.couponUrl)
    }
  }

  const getImg = (): string => {
    if (offerItem?.headerImageUrl != null) {
      return offerItem.headerImageUrl
    }
    return placeholderOffer
  }

  const codeButton = (redemption: Redemption): ReactElement => {
    return (
      <div
        className={`redemption-drawer-code__copy ${
          isFullWidth ? 'maxw-100' : ''
        }`}
      >
        <InputBase
          value={redemption.CouponCode}
          inputProps={{ ref: textCopyRef }}
          id={IdList.redemptionCode}
          onClick={() => copyToClipboardFromInput(textCopyRef)}
          className='redemption-drawer-code__copy__input'
          autoFocus={false}
          readOnly
        />
        <Button
          id={
            isApplyCode(redemption.couponUrl)
              ? IdList.redemptionApplyCode
              : IdList.redemptionCopyCode
          }
          onClick={
            isApplyCode(redemption.couponUrl)
              ? applyCode
              : () => copyToClipboardFromCTA(textCopyRef)
          }
          className='redemption-drawer-code__copy__btn'
        >
          {isApplyCode(redemption.couponUrl) ? t`ApplyCode` : t`CopyCode`}
        </Button>
      </div>
    )
  }

  const uniqueCodeRow = (
    fieldName: string,
    fieldValue: string,
    ref: React.MutableRefObject<HTMLInputElement | undefined>,
    id: string,
    showBtn: boolean
  ): ReactElement => {
    return (
      <div className='code-row'>
        <div className='field-name'>{fieldName}</div>
        <div className='field-value'>
          <InputBase
            value={fieldValue}
            inputProps={{ ref }}
            id={id}
            onClick={() => copyToClipboardFromInput(ref)}
            className='code-input'
            autoFocus={false}
            readOnly
          />
          {showBtn && (
            <Button
              id={id}
              onClick={
                redemption?.couponUrl != null
                  ? applyCode
                  : () => copyToClipboardFromCTA(ref)
              }
              className={`copy-btn ${
                redemption?.couponUrl != null ? 'apply-btn' : ''
              }`}
            >
              {redemption?.couponUrl != null ? (
                <LaunchOutlinedIcon />
              ) : (
                <FileCopyOutlinedIcon />
              )}
            </Button>
          )}
        </div>
      </div>
    )
  }

  const uniqueCodeFieldsDetails = (redemption: Redemption): ReactElement => {
    return (
      <div
        className={`redemption-drawer-code-unique ${
          isFullWidth && isFromUpdatedDesktopUI ? 'maxw-100' : ''
        }`}
      >
        {redemption.FieldMappings != null && fields != null
          ? Object.keys(fields).map((field, index) => {
            return (
              index < MAX_FIELDS_LIMIT && (
                <>
                  {uniqueCodeRow(
                    field,
                    fields[field],
                    index === FIRST_INDEX
                      ? textCopyRef
                      : index === SECOND_INDEX
                        ? textCopyRef2
                        : textCopyRef3,
                      `${IdList.redemptionCode}-${index}`,
                      !(redemption?.couponUrl != null && index !== 0)
                  )}
                  {index !== Object.keys(fields).length - 1 && (
                    <Divider className='divider' />
                  )}
                </>
              )
            )
          })
          : uniqueCodeRow(
            t`Code`,
            redemption.CouponCode,
            textCopyRef,
            IdList.redemptionCode,
            true
          )}
      </div>
    )
  }

  const getTermsAndCondition = (
    offerItem: Offer | undefined
  ): ReactElement | null => {
    if (offerItem != null) {
      if (
        offerItem.TermsAndConditionsBody != null &&
        offerItem.TermsAndConditionsTitle != null
      ) {
        return (
          <ButtonBase className='terms-link align-left' onClick={handleOpenTerms}>
            {offerItem.TermsAndConditionsTitle}
          </ButtonBase>
        )
      }
    }
    return null
  }

  const getInstruction = (
    offerItem: Offer | undefined
  ): ReactElement | null => {
    if (offerItem != null) {
      return (
        <div className='redemption-drawer-code__instruction__list'>
          <ReactMarkdown linkTarget='_blank'>
            {offerItem.CouponInstructions}
          </ReactMarkdown>
        </div>
      )
    }
    return null
  }
  const closeButton = (): ReactElement => {
    return (
      <div>
        {!matchesMd && (
          <Button
            id={IdList.redemptionClose}
            onClick={handleCloseRedemption}
            className='btn btn__small m-top-16'
          >
            {t`Close`}
          </Button>
        )}
      </div>
    )
  }

  const getCouponLink = (): ReactElement => {
    return (
      <div
        className={`redemption-drawer-code__action ${
          isFromUpdatedDesktopUI ? 'action-updated' : ''
        }`}
      >
        <Link
          component='button'
          className='redemption-drawer-code__action__link align-left'
          onClick={handleOpenHistory}
        >
          {t`CouponLink`}
        </Link>
      </div>
    )
  }
  const getOfferImg = (): ReactElement => {
    return (
      <div
        style={{ backgroundImage: `url(${getImg()})` }}
        role='img'
        aria-label='A banner image of the offer that use redemption with code'
        className={`${
          isFromUpdatedUI
            ? `redemption-drawer-new-img ${
                isFromUpdatedDesktopUI ? 'img-updated' : ''
              }`
            : 'redemption-drawer-code__img'
        }`}
      />
    )
  }
  const getOfferTitle = (className: string): ReactElement => {
    return <div className={className}>{offerItem?.Title}</div>
  }
  const getOfferSubtitle = (): ReactElement | null => {
    if (offerItem?.SubTitle != null) {
      return (
        <div className='redemption-drawer-new__subtitle'>
          {offerItem.SubTitle}
        </div>
      )
    }
    return null
  }
  const getHeaderForUpdatedMobileUI = (): ReactElement => {
    return (
      <div className='header-container'>
        <div className='image-container'>
          <img src={offerItem?.headerImageUrl} alt='offer' />
        </div>
        <div className='text-container'>
          {getOfferSubtitle()}
          {getOfferTitle('redemption-drawer-new__title mt-0')}
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${
        isFromUpdatedUI
          ? 'redemption-drawer-new'
          : `redemption-drawer-code ${isCustom ? 'custom-padding' : ''}`
      } `}
    >
      {!isFromUpdatedMobileUI && getOfferImg()}
      <div
        className={`${redemptionClassName}-details ${
          isFromUpdatedDesktopUI
            ? 'code-drawer-updated custom-padding-updated'
            : ''
        }`}
      >
        <div
          className={`${redemptionClassName}__info ${
            isFromUpdatedDesktopUI ? 'info-updated' : ''
          }`}
        >
          {isFromUpdatedMobileUI && getHeaderForUpdatedMobileUI()}
          {isFromUpdatedDesktopUI && getOfferSubtitle()}
          {!isFromUpdatedMobileUI &&
            getOfferTitle(`${redemptionClassName}__title`)}
          {isFromUpdatedUI ? (
            <>
              {redemption !== undefined && uniqueCodeFieldsDetails(redemption)}
              <div className={`${redemptionClassName}__instruction`}>
                {t`Instructions`}
              </div>
              {getInstruction(offerItem)}
              {getCouponLink()}
              {getTermsAndCondition(offerItem)}
            </>
          ) : (
            <>
              {redemption !== undefined && codeButton(redemption)}
              <div className={`${redemptionClassName}__instruction`}>
                {t`Instructions`}
              </div>
              {getInstruction(offerItem)}
              {getTermsAndCondition(offerItem)}
              {getCouponLink()}
            </>
          )}
        </div>
        {!isFromUpdatedUI && closeButton()}
      </div>
    </div>
  )
}

export default RedemptionWithCode
