import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import './FavoriteOfferItem.scss'
import _ from 'lodash'
import { IconButton, useMediaQuery } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useDispatch } from 'react-redux'
import { getOfferList, setFavoriteOffer } from '../../actions'
import { useTracking } from 'react-tracking'
import { getFavoriteOfferCount } from '../../../user/actions'
import { breakpoints } from '../../../core/utils/css-selectors'
import { RedeemCalc } from '../OfferListItem/OfferListItem'

interface FavoriteOfferItemProps {
  handleOfferItemClick: () => void
  handleUnavailableOfferItemClick: () => void
  points: ReactElement
  subTitle: string
  title: string
  isOfferStatusSoldOut: boolean
  isOfferStatusUnavailable: boolean
  isBlocked: boolean
  categoryInfo: OfferListByCategory
  OfferID: string
  isFavorite: boolean
  disableFavorite: boolean
  redeemCalc: RedeemCalc
}

const FavoriteOfferItem: FC<FavoriteOfferItemProps> = ({
  handleOfferItemClick,
  points,
  subTitle,
  title,
  isOfferStatusSoldOut,
  isOfferStatusUnavailable,
  handleUnavailableOfferItemClick,
  isBlocked,
  categoryInfo,
  OfferID,
  isFavorite,
  disableFavorite,
  redeemCalc
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { trackEvent } = useTracking()
  const handleClick = (): void => {
    isOfferStatusUnavailable
      ? handleUnavailableOfferItemClick()
      : handleOfferItemClick()
  }
  const handleFavoriteOffer = async (
    OfferID: string,
    isFavorite: boolean
  ): Promise<void> => {
    await Promise.all([
      await dispatch(setFavoriteOffer(OfferID, !isFavorite)),
      dispatch(getOfferList()),
      dispatch(getFavoriteOfferCount())
    ])
    trackEvent({
      category: 'Redemption',
      action: isFavorite ? 'Unmark Favorite' : 'Mark Favorite',
      payload: {
        title: isFavorite ? 'Unmark Favorite' : 'Mark Favorite',
        description: 'ID of the Offer',
        value: OfferID
      }
    })
  }
  return (
    <div className='favorite-offer-wrapper'>
      <div
        className={`favorite-list-item ${
          isBlocked || isOfferStatusSoldOut
            ? 'blocked-cursor'
            : 'available-cursor'
        } ${!_.isEmpty(subTitle) || matchesMd ? 'extend-height' : ''}`}
        onClick={handleClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick()
          }
        }}
      >
        <div className='favorite-offer-upper'>
          <div className='img-wrapper'>
            {!_.isEmpty(categoryInfo.iconImageUrl) && (
              <img
                src={categoryInfo.iconImageUrl}
                alt='Offer List Category Icon favorite-img'
                className='m-right-8 updated-UI-icon'
              />
            )}
          </div>
          <span
            className={`width-93 category-name capitalize-text ${
              matchesMd ? 'category-name__desktop' : ''
            }`}
          >
            {categoryInfo.CategoryName}
          </span>
        </div>
        <div className='main favorite-main'>
          <div className='offer-info'>
            <p className={`subheader ${matchesMd ? 'subheader__desktop' : ''}`}>
              {subTitle}
            </p>
            <p
              className={`title ${_.isEmpty(subTitle) ? 'mt-25' : ''} ${
                matchesMd ? 'title__desktop' : ''
              }`}
            >
              {title}
            </p>
          </div>
          <div className='status'>
            {isOfferStatusSoldOut && <p className='sold-out'>{t`SoldOut`}</p>}
            {isOfferStatusUnavailable && (
              <p className='unavailable'>{t`Unavailable`}</p>
            )}
            <div
              className={`offer-points ${
                isOfferStatusSoldOut ||
                isOfferStatusUnavailable ||
                redeemCalc.difference < 0
                  ? 'unavailable-background'
                  : 'available-background'
              }
              ${matchesMd ? 'offer-points__desktop' : ''}`}
            >
              {points}
            </div>
          </div>
        </div>
      </div>
      <IconButton
        onClick={async () => await handleFavoriteOffer(OfferID, isFavorite)}
        className={`favorite-icon ${
          !isFavorite && disableFavorite ? 'favorite-disabled' : ''
        }`}
      >
        {isFavorite ? (
          <FavoriteIcon className='selected-favorite-icon' />
        ) : (
          <FavoriteBorderIcon className='unselected-favorite-icon' />
        )}
      </IconButton>
    </div>
  )
}

export default FavoriteOfferItem
