import React, { FC, ReactElement, useState } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import OfferListItem from '../OfferListItem/OfferListItem'
import { breakpoints } from '../../../core/utils/css-selectors'

import './FavoriteOfferList.scss'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectFavoriteOfferCount } from '../../../user/selectors'

interface FavoriteOfferListProps {
  offerCategoryList: OfferListByCategory[]
  categoryIndex: any
  handleClickLockedOffer: () => void
  searchFilter: string
  allowFilter: boolean
}
const MAX_FAVORITE_OFFERS_FOR_OPEN = 3
const FavoriteOfferList: FC<FavoriteOfferListProps> = ({
  offerCategoryList,
  categoryIndex,
  handleClickLockedOffer,
  allowFilter,
  searchFilter
}) => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const favoriteCount: number = useSelector(selectFavoriteOfferCount)
  const [expandOffers, setExpandOffers] = useState<boolean>(
    matchesMd ? true : favoriteCount <= MAX_FAVORITE_OFFERS_FOR_OPEN
  )
  const renderOfferItem = (
    offerItem: Offer,
    index: number,
    offerCategory: OfferListByCategory
  ): Boolean | ReactElement => {
    const shouldRender = allowFilter
      ? offerItem.Title.toUpperCase().includes(searchFilter.toUpperCase()) &&
        offerItem.isFavorite
      : offerItem.isFavorite
    return (
      shouldRender && (
        <div className={`${matchesMd ? 'favorite-item-wrapper' : ''}`}>
          <OfferListItem
            key={offerItem.OfferID}
            offerItem={offerItem}
            handleClickLockedOffer={handleClickLockedOffer}
            isFavoriteOfferAvailable
            categoryInfo={offerCategory}
            showUpdatedUI={!matchesMd}
          />
        </div>
      )
    )
  }
  const showValue = (): ReactElement => {
    if (favoriteCount < 1) {
      return (
        <div className='favorite-offers-updated'>
          <p className='no-favorite-offer-text-title'>{t`NoFavoriteOffersTitle`}</p>
          <p className='no-favorite-offer-text'>{t`NoFavoriteOffers`}</p>
        </div>
      )
    } else {
      return (
        <div
          className={`favorite-offers-updated ${
            matchesMd ? 'favorite-offers-updated__desktop' : ''
          }`}
        >
          {offerCategoryList.map(
            (offerCategory: OfferListByCategory, i) =>
              offerCategory.offerList?.map(
                (offerItem: Offer, index) =>
                  offerItem.isFavorite && expandOffers && (
                    renderOfferItem(offerItem, index, offerCategory)
                  )
              )
          )}
        </div>
      )
    }
  }
  return (
    <div className='offer-list-category favorite-list'>
      <div className='offer-list-category__title mb-1'>
        <FavoriteIcon className='favorite-icon' />
        <span className='width-93 favorite-title'>
          {t`Favorites`}
        </span>
        {!matchesMd && (
          <button
            onClick={() => setExpandOffers(!expandOffers)}
            className='overlay-button'
            aria-label='expand offer category list'
          >
            <span>{expandOffers ? <ExpandLess /> : <ExpandMore />}</span>
          </button>
        )}
      </div>
      <div>{showValue()}</div>
    </div>
  )
}

export default FavoriteOfferList
