import _ from 'lodash'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { useDispatch, useSelector } from 'react-redux'

import { useMediaQuery } from '@react-hook/media-query'

import CampaignDrawer from '../CampaignDrawer/CampaignDrawer'
import { selectCampaignStatus, selectLockedCampaign } from '../selectors'
import { CampaignStatusList } from '../campaignStatus'
import AvailableMessage from '../../ui/AvailableMessage/AvailableMessage'
import { getCampaignStatuses } from '../actions'
import { ProviderStatusType } from '../../provider/providerStatusType'
import CampaignIco from '../CampaignIco/CampaignIco'
import { selectCurrentTutorialState } from '../../tutorial/selectors'
import placeholderCampaign from '../../../assets/images/campaign/placeholder-campaign.png'
import { CampaignTypes } from '../CampaignConstants'
import { selectConfig } from '../../config/selectors'
import StreakProgressUpdated from '../../ui/Streak/StreakProgressUpdated'
import { selectUser } from '../../user/selectors'
import CampaignDrawerUpdated from '../CampaignDrawer/CampaignDrawerUpdated'
import StreakProgressWithTimeDistinct from '../../ui/Streak/StreakProgressWithTimeDistinct'
import { breakpoints } from '../../core/utils/css-selectors'
import TierProgress from '../../ui/Tier/TierProgress'

import './Campaign.scss'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import OpenProviderDialog from '../../provider/ProviderList/OpenProviderDialog/OpenProviderDialog'

interface CampaignCardProps {
  campaignItem: Campaign
  indexing?: number
  categoryIndex?: number
  containerClassName?: string
  imgClassName?: string
  campaignDetailsClassName?: string
}

const CampaignCard: FC<CampaignCardProps> = ({
  campaignItem,
  indexing,
  categoryIndex,
  containerClassName = '',
  imgClassName = '',
  campaignDetailsClassName = ''
}) => {
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const config: ConfigState = useSelector(selectConfig)
  const dispatch = useDispatch()
  const [isCampaignDetailsOpen, setIsCampaignDetailsOpen] = useState(false)
  const campaignStatus: CampaignStatus | undefined = useSelector(
    selectCampaignStatus(campaignItem.CampaignID)
  )
  const lockedByProviders: ProviderStatus[] | undefined = useSelector(
    selectLockedCampaign(campaignItem.providers)
  )
  const [isCampaignUnavailable, setIsCampaignUnavailable] = useState(false)
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false)
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const { featureFlags }: UserState = useSelector(selectUser)
  const isConnectCampaign = campaignItem.CampaignType === CampaignTypes.CONNECT
  const showsPts =
    campaignItem.CampaignType === CampaignTypes.STREAK
      ? campaignStatus?.streakLevelRewards > 0
      : campaignItem.CampaignType === CampaignTypes.TIER
        ? campaignStatus?.currentTierPoints > 0
        : campaignItem.RewardPerUser > 0
  const isDesktop = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const trackLockedIconClick = useCallback((): void => {
    trackEvent({
      page: AnalyticsCategory.Campaigns,
      action: 'Campaign Lock Pressed',
      payload: {
        origin: 'campaign card'
      }
    })
  }, [trackEvent])

  const getCampaignLocked = (
    lockedByProviders: ProviderStatus[] = []
  ): boolean => {
    return Boolean(
      lockedByProviders.find(
        (e: any) =>
          e.status !== ProviderStatusType.Connected &&
          e.status !== ProviderStatusType.NotValidProvider &&
          e.status !== ProviderStatusType.RequireDisconnect
      )
    )
  }

  const getCampaignProvidersNotConnected = useCallback(():
  | ProviderStatus[]
  | undefined => {
    if (getCampaignLocked(lockedByProviders) && !isConnectCampaign) {
      return lockedByProviders?.filter(
        (provider) =>
          provider.status !== ProviderStatusType.Connected &&
          provider.status !== ProviderStatusType.NotValidProvider &&
          provider.status !== ProviderStatusType.RequireDisconnect
      )
    } else {
      return []
    }
  }, [lockedByProviders, isConnectCampaign])

  const handleLockIconClick = useCallback(
    (flag: boolean): void => {
      trackLockedIconClick()
      setIsCampaignDetailsOpen(flag)
    },
    [trackLockedIconClick]
  )

  const hideCampaign = (lockedByProviders: ProviderStatus[] = []): boolean => {
    return Boolean(
      lockedByProviders.find(
        (e: any) =>
          e.status === ProviderStatusType.NotValidProvider ||
          e.status === ProviderStatusType.RequireDisconnect
      )
    )
  }
  const isCampaignTimeLimited = (): boolean => {
    return campaignStatus?.status === CampaignStatusList.TimeLimited
  }

  const openUnavailableMessage = useCallback((): void => {
    dispatch(getCampaignStatuses())
      .then(() => {
        setIsCampaignDetailsOpen(false)
      })
      .then(() => {
        setIsCampaignUnavailable(true)
      })
  }, [dispatch])

  const handleIconClick = useCallback((): void => {
    if (getCampaignLocked(lockedByProviders)) {
      handleLockIconClick(true)
    } else if (campaignStatus?.status === CampaignStatusList.TimeLimited) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Timer Pressed',
        payload: {
          origin: 'campaign card'
        }
      })
      openUnavailableMessage()
    } else if (campaignStatus?.status === CampaignStatusList.Locked) {
      trackLockedIconClick()
    }
  }, [
    campaignStatus?.status,
    lockedByProviders,
    trackLockedIconClick,
    openUnavailableMessage,
    trackEvent,
    handleLockIconClick
  ])

  const handleCloseUnavailableMessage = (): void => {
    setIsCampaignUnavailable(false)
  }

  const handleToggleDrawer = (flag: boolean): void => {
    if (getCampaignLocked(lockedByProviders) && !isConnectCampaign) {
      handleLockIconClick(flag)
    } else {
      setIsCampaignDetailsOpen(flag)
    }
  }
  const getImg = (): string => {
    if (campaignItem?.headerImageUrl != null) {
      return campaignItem.headerImageUrl
    }
    return placeholderCampaign
  }

  const getPoints = (): string => {
    let points = 0
    if (
      campaignItem?.CampaignType === CampaignTypes.STREAK &&
      campaignStatus?.streakLevelRewards > 0
    ) {
      points = campaignStatus?.streakLevelRewards
    } else if (
      campaignItem?.CampaignType === CampaignTypes.TIER &&
      campaignStatus?.currentTierPoints > 0
    ) {
      points = campaignStatus?.currentTierPoints
    } else {
      points = campaignItem?.RewardPerUser ?? 0
    }
    return points.toString()
  }

  const getPointsField = (): string => {
    if (config.WordsForPoints.short != null) {
      return t([config.WordsForPoints.short])
    }
    return t`pts`
  }

  const isLocked =
    !isConnectCampaign &&
    (getCampaignLocked(lockedByProviders) ||
      campaignStatus?.status === CampaignStatusList.Locked)

  const isUnavailable = isLocked || isCampaignTimeLimited()
  const validStreakCampaign =
    campaignItem.CampaignType === CampaignTypes.STREAK && campaignStatus != null
  const validTierCampaign =
    campaignItem.CampaignType === CampaignTypes.TIER && campaignStatus != null
  const isTimeLimitedCampaignStatus =
    campaignStatus !== undefined &&
    campaignStatus.status === CampaignStatusList.TimeLimited
  return !hideCampaign(lockedByProviders) ? (
    <>
      <div
        className={`${containerClassName} campaign-list-item`}
        onClick={() => handleToggleDrawer(true)}
        role='button'
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleToggleDrawer(true)
          }
        }}
      >
        <div
          className={`${imgClassName} campaign-img ${
            isUnavailable ? 'locked' : ''
          }`}
          style={{ backgroundImage: `url(${getImg()})` }}
          role='img'
          aria-label='A promotional banner for the campaign'
        >
          {campaignStatus !== undefined && !isConnectCampaign && (
            <CampaignIco
              campaignStatus={campaignStatus}
              handleBadge={handleIconClick}
              campaignLocked={getCampaignLocked(lockedByProviders)}
              shadow
            />
          )}
        </div>

        <div
          className={`campaigns-details-container ${campaignDetailsClassName}`}
        >
          <div>
            {!_.isEmpty(campaignItem.SubHeader) && (
              <div className='subheader-1'>{campaignItem.SubHeader}</div>
            )}
            <div
              className={`fw-700 campaign-title ${
                _.isEmpty(campaignItem.SubTitle) ? 'mb-1' : ''
              }`}
            >
              {campaignItem.Title}
            </div>
            {!_.isEmpty(campaignItem.SubTitle) && (
              <div className='subheader-2'>{campaignItem.SubTitle}</div>
            )}
          </div>

          {validStreakCampaign && (
            <>
              {(campaignStatus?.hasTimeDistinct as boolean) ? (
                <StreakProgressWithTimeDistinct
                  currentLevel={campaignStatus?.streakCurrentLevel}
                  totalLevel={campaignStatus?.streakTotalLevels}
                  daysLeft={campaignStatus?.streakDaysLeft}
                  completed={campaignStatus?.streakCompleted}
                  streakTarget={campaignStatus?.streakTarget}
                  behavioursCount={
                    campaignStatus?.behavioursCountInTimeDistinct
                  }
                  timeDistinctCount={campaignStatus?.timeDistinctCount}
                  timeDistinctUnit={String(campaignStatus?.timeDistinctUnit)}
                  fromCard
                  extraCssForList
                />
              ) : (
                <StreakProgressUpdated
                  trips={campaignStatus?.progress}
                  completion={campaignStatus?.streakCurrentLevelCompletion}
                  currentLevel={campaignStatus?.streakCurrentLevel}
                  totalLevel={campaignStatus?.streakTotalLevels}
                  daysLeft={campaignStatus?.streakDaysLeft}
                  completed={campaignStatus?.streakCompleted}
                  streakLevelTarget={campaignStatus?.streakTarget}
                />
              )}
            </>
          )}
          {validTierCampaign && (
            <div
              className={`${!_.isEmpty(campaignItem.SubTitle) ? 'mt-1' : ''} ${
                !isUnavailable && showsPts ? 'mb-075' : ''
              }`}
            >
              <TierProgress
                tierProperties={campaignStatus?.tierPropertiesForCampaign}
                tierProgress={campaignStatus?.tierProgress}
                isLastTierCompleted={campaignStatus?.isLastTierCompleted}
              />
            </div>
          )}
          <div className={`campaigns-info ${isDesktop ? 'desktop' : 'mobile'}`}>
            <div className='campaign-status'>
              <p
                className={
                  isLocked
                    ? 'locked-text'
                    : campaignStatus?.status === CampaignStatusList.TimeLimited
                      ? 'sold-out-text'
                      : ''
                }
              >
                {isLocked
                  ? t`AccountRequired`
                  : campaignStatus?.status === CampaignStatusList.TimeLimited
                    ? t`LimitReached`
                    : ''}
              </p>
            </div>
            {showsPts && (
              <div
                className={`campaign-points ${
                  isUnavailable ? 'locked-background' : ''
                }`}
              >
                {getPoints() + ' ' + getPointsField()}
              </div>
            )}
          </div>
        </div>
      </div>

      {featureFlags.UX_UPDATES ? (
        <CampaignDrawerUpdated
          isCampaignDetailsOpen={
            isCampaignDetailsOpen ||
            (currentTutorialState.tutorialOn &&
              indexing === 0 &&
              categoryIndex === 0)
          }
          setIsCampaignDetailsOpen={handleToggleDrawer}
          campaignItem={campaignItem}
          handleOpenUnavailableMessage={openUnavailableMessage}
          campaignProvidersNotConnected={
            getCampaignProvidersNotConnected() ?? []
          }
        />
      ) : (
        <CampaignDrawer
          isCampaignDetailsOpen={
            isCampaignDetailsOpen ||
            (currentTutorialState.tutorialOn &&
              indexing === 0 &&
              categoryIndex === 0)
          }
          setIsCampaignDetailsOpen={handleToggleDrawer}
          campaignItem={campaignItem}
          handleOpenUnavailableMessage={openUnavailableMessage}
        />
      )}

      <OpenProviderDialog
        setIsConnectDialogOpen={setIsConnectDialogOpen}
        isConnectDialogOpen={isConnectDialogOpen}
        campaignItem={campaignItem}
      />

      {isTimeLimitedCampaignStatus && (
        <AvailableMessage
          isAvailableMessageOpen={isCampaignUnavailable}
          handleCloseAvailableMessage={handleCloseUnavailableMessage}
          nextResetAtUTC={
            campaignStatus != null ? campaignStatus.nextPeriodStartUTC : ''
          }
        />
      )}
    </>
  ) : (
    <></>
  )
}

export default CampaignCard
