import React, { FC } from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import { useTracking } from 'react-tracking'
import NumberFormat from 'react-number-format'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import { RedeemCalc } from '../OfferListItem/OfferListItem'
import { OfferStatus } from '../../offerStatus'
import placeholderOffer from '../../../../assets/images/offer/placeholder-offer.png'
import { redeemOffer, getOfferList } from '../../actions'
import {
  getUserInfo,
  getUserOfferStatus,
  getEarningList
} from '../../../user/actions'
import { selectOfferStatus } from '../../../user/selectors'
import { diffFromNow } from '../../../core/utils/time'
import { IdList } from '../../../core/utils/id-list'
import { selectConfig } from '../../../config/selectors'
import {
  getCampaignList,
  getCampaignFeaturedList,
  getCampaignStatuses
} from '../../../campaign/actions'
import './OfferDrawer.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import MDEditor from '@uiw/react-md-editor'
import { CUSTOME_ERROR_MESSAGE } from '../../../constants'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

interface OfferDrawerProps {
  offerItem: Offer
  isOfferDetailsOpen: boolean
  setIsOfferDetailsOpen: (isOfferDetailsOpen: boolean) => void
  redeemCalc: RedeemCalc
  handleRedeemOffer: (offerRedemption: Redemption) => void
  showSpinner: () => void
  handleRedeemOfferError: () => void
  handleOpenTerms: () => void
}

const OfferDrawer: FC<OfferDrawerProps> = ({
  offerItem,
  isOfferDetailsOpen,
  setIsOfferDetailsOpen,
  redeemCalc,
  handleRedeemOffer,
  showSpinner,
  handleRedeemOfferError,
  handleOpenTerms
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const offerItemStatus: UserOfferStatus | undefined = useSelector(
    selectOfferStatus(offerItem.OfferID)
  )
  const config: ConfigState = useSelector(selectConfig)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const handleClickRedeemButton = async (event: any = null): Promise<any> => {
    if (event != null) {
      event.preventDefault()
    }
    showSpinner()

    return dispatch(redeemOffer(offerItem.OfferID))
      .then((response: Redemption) => {
        handleRedeemOffer(response)
      })
      .then(() => {
        trackEvent({
          page: AnalyticsCategory.Redemption,
          action: 'Offer Redeemed',
          payload: {
            offerID: offerItem.OfferID
          }
        })
      })
      .then(async () => {
        return await Promise.all([
          dispatch(getUserInfo()),
          dispatch(getEarningList()),
          dispatch(getCampaignList()),
          dispatch(getCampaignFeaturedList()),
          dispatch(getCampaignStatuses()),
          dispatch(getUserOfferStatus()),
          dispatch(getOfferList())
        ])
      })
      .catch((error: Error) => {
        handleRedeemOfferError()
        toast.error(
          error?.message != null && error?.message.length > 0
            ? error?.message
            : `${CUSTOME_ERROR_MESSAGE} redeeem offer`
        )
        Sentry.captureException(error, {
          tags: {
            method: 'handleClickRedeemButton-OfferDrawer'
          }
        })
      })
  }

  const getImg = (): string =>
    offerItem.headerImageUrl != null
      ? offerItem.headerImageUrl
      : placeholderOffer

  const getPointsField = (): string => {
    if (config.WordsForPoints.long != null) {
      return t([config.WordsForPoints.long])
    }
    return t`points`
  }

  const selectButtonType: any = () => {
    if (
      offerItemStatus !== undefined &&
      offerItemStatus.status === OfferStatus.unavailableLimit
    ) {
      return (
        <Button
          disabled
          classes={{ disabled: 'btn-disable' }}
          className='btn m-top-24'
        >
          {`${t`AvailableIn`} ${diffFromNow(offerItemStatus.NextResetAtUTC)}`}
        </Button>
      )
    } else {
      if (redeemCalc.isAvailable) {
        return (
          <>
            <div className='m-top-16 offer-drawer__new-balance'>
              {offerItem.VeloAmount !== 0 && (
                <>
                  <span>{t`NewBalance`}</span>
                  <span>
                    <NumberFormat
                      value={redeemCalc.difference}
                      displayType='text'
                      thousandSeparator
                    />
                  </span>
                </>
              )}
            </div>
            <Button
              id={IdList.redeemFor}
              onClick={handleClickRedeemButton}
              onTouchEnd={handleClickRedeemButton}
              className='btn m-top-24'
            >
              {offerItem.VeloAmount !== 0 ? (
                <>
                  {t`RedeemFor`}
                  &nbsp;
                  <NumberFormat
                    value={offerItem.VeloAmount}
                    displayType='text'
                    thousandSeparator
                  />
                  &nbsp;
                  {getPointsField()}
                </>
              ) : (
                t`RedeemForFree`
              )}
            </Button>
          </>
        )
      } else {
        return (
          <Button
            disabled
            classes={{ disabled: 'btn-disable' }}
            className='btn m-top-24'
          >
            {`${t('morePoints', { points: Math.abs(redeemCalc.difference) })}`}
          </Button>
        )
      }
    }
  }

  return (
    <BaseDrawer
      isOpen={isOfferDetailsOpen}
      onClose={() => setIsOfferDetailsOpen(false)}
      customMargin={matchesMd ? '45px' : ''}
      aria-labelledby='offer-drawer-title'
    >
      <div className='offer-drawer'>
        <div className='offer-drawer__header'>
          <div
            style={{ backgroundImage: `url(${getImg()})` }}
            className='offer-drawer__header__img'
            role='img'
            aria-label='A promotional banner for the offer'
          />
          <div
            className='offer-drawer__header__title'
            aria-labelledby={offerItem.Title}
          >
            {offerItem.Title}
          </div>
        </div>

        {offerItem.LimitTimeFrameInDays !== 0 && (
          <span className='offer-drawer__timer'>
            <TimerIcon className='icon__ico offer-drawer__timer__ico' />
            <span className='offer-drawer__timer__txt'>
              {`x${offerItem.RedemptionsAllowedInTimeFrame}`} {t`per`}{' '}
              {offerItem.LimitTimeFrameInDays === 1
                ? `${t`DAY`}`
                : `${offerItem.LimitTimeFrameInDays} ${t`DAYS`}`}
            </span>
          </span>
        )}
        <div className='offer-drawer__info'>
          <MDEditor.Markdown
            source={offerItem.Description}
            style={{ whiteSpace: 'pre-wrap' }}
            aria-label='Offer Description'
          />
        </div>
        {offerItem.TermsAndConditionsBody != null &&
        offerItem.TermsAndConditionsTitle != null && (
          <ButtonBase
            className='terms-link'
            onClick={handleOpenTerms}
            role='button'
            aria-label='Terms and Conditions'
          >
            {offerItem.TermsAndConditionsTitle}
          </ButtonBase>
        )}
      </div>

      {selectButtonType()}
    </BaseDrawer>
  )
}

export default OfferDrawer
