import { FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import track, { useTracking } from 'react-tracking'
import { Link, useHistory } from 'react-router-dom'
import {
  TextField,
  Box,
  Button,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { useFormik } from 'formik'
import { resetSignInError, setHomePath, signIn } from '../actions'
import { RoutePath } from '../../core/routes/route-path'
import { IdList } from '../../core/utils/id-list'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import { useTranslation } from 'react-i18next'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import './SignIn.scss'
import { selectAuth } from '../selectors'
import InvalidCredentialsDialog from './InvalidCredentialsDialog'
import {
  AUTH_INDICATOR_SIZE,
  NO_ATTEMPT_LEFT,
  ONE_ATTEMPT_LEFT,
  PASSWORD,
  PASSWORD_EXPIRED,
  TEXT,
  regexEmail
} from '../constants'
import { selectConfig } from '../../config/selectors'
import { useMediaQuery } from '@react-hook/media-query'
import image from '../../../assets/images/pattern.png'
import welcomeImage from '../../../assets/images/welcome/main.png'
import FailedAttemptsLeftDialog from './FailedAttemptsLeftDialog'
import Spinner from '../../ui/animation/Spinner/Spinner'
import { setHasJustLoggedIn } from '../../user/actions'
import { parseCssTheme } from '../../core/utils/useTheme'
import PasswordExpiredDialog from '../PasswordExpiry/PasswordExpiredDialog'

const SignIn: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<UserAction> = useDispatch()
  const { trackEvent } = useTracking()
  const history = useHistory()
  const authState = useSelector(selectAuth)
  const [isError, setIsError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const initialValues: SignInForm = { email: '', password: '' }
  const config: ConfigState = useSelector(selectConfig)
  const matchesMdw = useMediaQuery('(min-width: 1024px)')
  const matchesMdh = useMediaQuery('(min-height: 680px)')
  const [isLastAttemptLeft, setIsLastAttemptLeft] = useState<boolean>(false)
  const [isNoAttemptLeft, setIsNoAttemptLeft] = useState<boolean>(false)
  const [isPasswordExpired, setIsPasswordExpired] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const customTheme = parseCssTheme(config.CssTheme)

  const customLogoUrl = config.TenantLogoURL ?? ''

  useEffect(() => {
    const error = authState.error?.error_description
    if (error != null) {
      if (error.includes(ONE_ATTEMPT_LEFT)) {
        setIsLastAttemptLeft(true)
      } else if (error.includes(NO_ATTEMPT_LEFT)) {
        setIsNoAttemptLeft(true)
      } else if (error.includes(PASSWORD_EXPIRED)) {
        setIsPasswordExpired(true)
      } else {
        setIsError(true)
      }
    }
    return () => {
      setIsError(false)
      setIsNoAttemptLeft(false)
      setIsLastAttemptLeft(false)
      setIsPasswordExpired(false)
    }
  }, [authState.error?.error_description])

  useEffect(() => {
    dispatch(setHomePath(false))
  }, [dispatch])

  const handleDialogClose = (): void => {
    setIsError(false)
    setIsLastAttemptLeft(false)
    setIsNoAttemptLeft(false)
    setIsPasswordExpired(false)
    dispatch(resetSignInError({}))
  }

  const handleSignUpClick = (): void => {
    history.push(RoutePath.SignUp)
    handleDialogClose()
    trackEvent({
      page: 'SignUp',
      action: 'Request to SignUp',
      payload: {
        origin: 'AccountNotFound'
      }
    })
  }

  const handleResetPasswordClick = (): void => {
    history.push(RoutePath.PasswordReset)
    handleDialogClose()
  }

  const handleAccountBlockedClick = (): void => {
    history.push(RoutePath.PasswordReset)
    handleDialogClose()
    trackEvent({
      page: 'Reset Password',
      action: 'Forgot Password?',
      payload: {
        origin: 'AccountBlocked'
      }
    })
  }

  const trackEventOnSignUpClick = (): void => {
    trackEvent({
      page: 'SignUp',
      action: 'Request to SignUp',
      payload: {
        origin: 'SignIn'
      }
    })
  }

  const trackEventOnForgotPasswordClick = (): void => {
    trackEvent({
      page: 'Reset Password',
      action: 'Forgot Password?',
      payload: {
        origin: 'SignIn'
      }
    })
  }

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: SignInForm, action) => {
      const data = {
        ...formData,
        isStandalone: true,
        TenantID: config.TenantID,
        isReferralAvailable: config.IsReferralAvailable
      }
      trackEvent({
        page: 'SignIn',
        action: 'Sign In'
      })
      setLoading(true)
      dispatch(signIn(data))
        .then((res: SignInAction) => {
          if (res.payload) {
            action.resetForm()
            dispatch(setHasJustLoggedIn(true))
            history.push(RoutePath.Home)
          }
        })
        .finally(() => setLoading(false))
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {}
      if (values.email == null) {
        errors.email = t`MissingEmailError`
      } else if (!regexEmail.test(values.email)) {
        errors.email = t`InvalidEmailError`
      }
      if (values.password == null) {
        errors.password = t`MissingPasswordError`
      }
      return errors
    },
    validateOnChange: false
  })

  const isDisabled = form.values.email === '' || form.values.password === ''

  const formButton = (
    <div className='responsive-btn'>
      <Button
        id={IdList.signIn}
        type='submit'
        className={`btn lato ${isDisabled ? 'disabled' : ''}`}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        style={{
          backgroundColor: customTheme.button,
          color: customTheme.buttonText
        }}
      >
        {t`SignIn`}
      </Button>
    </div>
  )
  return isLoading ? (
    <Box className='spinner-container'>
      <Box className='spinner-holder'>
        <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
      </Box>
    </Box>
  ) : (
    <Box>
      <Box className='sign-in-container'>
        <Box className='sign-in-left'>
          <Box className='form-fields-box'>
            <Box className='tenant-logo'>
              <img
                src={customLogoUrl}
                alt='tenant logo'
                className={matchesMdh && matchesMdw ? 'custom-img' : ''}
              />
            </Box>
            <Typography className='sign-in-heading'>{t`SignIn!`}</Typography>
            <form onSubmit={form.handleSubmit} className='sign-in-form'>
              <Box>
                <Box mb={3}>
                  <TextField
                    id={IdList.signInEmailPhoneNumber}
                    name='email'
                    variant='filled'
                    className='form-control'
                    value={form.values.email}
                    onChange={form.handleChange}
                    label={t`EmailSignIn`}
                    InputLabelProps={{ className: 'lato label' }}
                    aria-describedby='email-error'
                  />
                  {form.errors.email != null && (
                    <FormHelperText id='email-error' error>{form.errors.email}</FormHelperText>
                  )}
                </Box>
                <Box mb={4}>
                  <TextField
                    id={IdList.signInPassword}
                    name='password'
                    type={showPassword ? TEXT : PASSWORD}
                    variant='filled'
                    className='form-control'
                    value={form.values.password}
                    onChange={form.handleChange}
                    label={t`Password`}
                    aria-describedby='password-error'
                    InputLabelProps={{ className: 'lato label' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon className='show-password-icon' />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {form.errors.password != null && (
                    <FormHelperText id='password-error' error>
                      {form.errors.password}
                    </FormHelperText>
                  )}
                </Box>
                {matchesMdw && matchesMdh && formButton}
                <Box className='sign-in-actions'>
                  <Link
                    to={RoutePath.PasswordReset}
                    className='no-decor forgot-button'
                    onClick={trackEventOnForgotPasswordClick}
                    aria-label='Forgot your password?'
                  >
                    <Typography className='link-text'>{t`ForgotPassword`}</Typography>
                  </Link>
                  <Box className='clearfix' />
                  <Typography className='text inline'>
                    {t`NoAccountText`}
                  </Typography>
                  <Link
                    to={RoutePath.SignUp}
                    className='no-decor'
                    onClick={trackEventOnSignUpClick}
                  >
                    <Typography className='link-text inline'>
                      {t`SignUp`}
                    </Typography>
                  </Link>
                </Box>
              </Box>
              {(!matchesMdw || !matchesMdh) && formButton}
            </form>
          </Box>
        </Box>
        {matchesMdw && matchesMdh && (
          <Box
            className='sign-in-right'
            style={{
              backgroundColor: customTheme.rightPanel,
              backgroundImage: `url(${image as string})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPositionY: '100%'
            }}
          >
            <Box className='steps-box'>
              <Box className='welcome-box'>
                <img
                  src={config.SignInScreenImage ?? welcomeImage}
                  alt='welcome'
                />
                <Typography style={{ color: customTheme.headerText }}>
                  {config.SignInScreenTitle}
                </Typography>
              </Box>
              {config.WelcomeStepsConfigSignIn?.map((step, index) => {
                return (
                  <Box className='step-info' key={`${index}-step`}>
                    <div className='step-header'>
                      <img src={step.StepImage} alt={`Logo for step ${index + 1}`} aria-hidden='true' />
                      <div>
                        <Typography style={{ color: customTheme.headerText }}>
                          {step.StepTitle}
                        </Typography>
                        <Typography
                          className='description'
                          style={{ color: customTheme.headerText }}
                        >
                          {step.StepDescription}
                        </Typography>
                      </div>
                    </div>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>
      {isError && (
        <InvalidCredentialsDialog
          open={isError}
          handleClose={handleDialogClose}
          handleSignUpClick={handleSignUpClick}
          aria-live='assertive'
        />
      )}
      {isLastAttemptLeft && (
        <FailedAttemptsLeftDialog
          open={isLastAttemptLeft}
          handleClose={handleDialogClose}
          handleButtonClick={handleDialogClose}
          title={t`OneAttemptLeft`}
          content={t`OneAttemptLeftContent`}
          buttonLabel={t`Ok`}
          aria-live='assertive'
        />
      )}
      {isNoAttemptLeft && (
        <FailedAttemptsLeftDialog
          open={isNoAttemptLeft}
          handleClose={handleDialogClose}
          handleButtonClick={handleAccountBlockedClick}
          title={t`AccountBlocked`}
          content={t`AccountBlockedContent`}
          buttonLabel={t`ResetPasswordBtn`}
          aria-live='assertive'
        />
      )}
      {isPasswordExpired && (
        <PasswordExpiredDialog
          open={isPasswordExpired}
          handleClose={handleDialogClose}
          handleResetPassword={handleResetPasswordClick}
          aria-live='assertive'
        />
      )}
    </Box>
  )
}

export default track({
  page: AnalyticsCategory.SignIn
})(SignIn)
