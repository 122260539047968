import React, { FC, useEffect } from 'react'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { selectUser } from '../../user/selectors'
import { UserStatus } from '../../user/userStatus'
import ReactMarkdown from 'react-markdown'
import { selectIsActionLoaded, selectConfig } from '../../config/selectors'
import { GET_CONFIG_INFO } from '../../config/actionTypes'
import history from '../../core/history'
import { RoutePath } from '../../core/routes/route-path'

import suspendImg from '../../../assets/images/suspension.png'
import './Suspended.scss'

const Suspended: FC = () => {
  const isConfigLoaded = useSelector<boolean>(
    selectIsActionLoaded(GET_CONFIG_INFO)
  )
  const config = useSelector(selectConfig)
  const user: UserState = useSelector(selectUser)

  useEffect(() => {
    if (user.UserID !== undefined) {
      if (user.userStatus !== UserStatus.Suspended) {
        history.replace(RoutePath.Home)
      }
    }
  }, [user, config])

  const checkSuspensionPageDescription = (): boolean => {
    if (config.SuspensionPageDescription !== null && config.SuspensionPageDescription !== undefined) {
      if (config.SuspensionPageDescription !== '') {
        return true
      }
    }

    return false
  }

  return (
    <div className='suspended'>
      <img
        src={suspendImg}
        className='suspended__img'
        alt='Suspended user icon'
      />

      {isConfigLoaded === true ? (
        <div className='suspended__title'>{config.SuspensionPageTitle}</div>
      ) : (
        <Skeleton variant='rect' classes={{ root: 'suspended__title' }} />
      )}

      <div className='suspended__instruction'>
        {checkSuspensionPageDescription() && (
          <ReactMarkdown linkTarget='_blank'>
            {config.SuspensionPageDescription}
          </ReactMarkdown>
        )}
      </div>
    </div>
  )
}

export default Suspended
