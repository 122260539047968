import { Box, Button, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './InviteCodeForm.scss'
import '../SignUp.scss'
import { Link } from 'react-router-dom'
import { optionalInviteCodeToggle } from '../../utils'

const InviteCodeForm = ({
  isInviteCodeDisabled,
  isInviteCodeMandatory,
  customTheme,
  handleInviteCodeSubmit,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  moreInformationUrl,
  moreInformationLink,
  moreInformationLinkDescription
}: InviteCodeFormProps): ReactElement => {
  const { t } = useTranslation()
  const [isInviteCodeToggleChecked, setIsInviteCodeToggleChecked] =
    useState<boolean>(false)
  const initialValues: InviteCodeForm = { inviteCode: '' }
  const i18t = useTranslation()

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: InviteCodeForm) => {
      const inviteCodeToSubmit =
        isInviteCodeToggleChecked || isInviteCodeMandatory
          ? formData.inviteCode
          : ''
      handleInviteCodeSubmit(inviteCodeToSubmit)
    }
  })

  const isDisabled =
    (isInviteCodeToggleChecked || isInviteCodeMandatory) &&
    form.values.inviteCode.length < 3

  return (
    <form onSubmit={form.handleSubmit}>
      {!isInviteCodeMandatory &&
        !isInviteCodeDisabled &&
        optionalInviteCodeToggle(
          i18t,
          setIsInviteCodeToggleChecked,
          isInviteCodeToggleChecked
        )}
      {(isInviteCodeMandatory || isInviteCodeToggleChecked) && (
        <TextField
          id='invite-code'
          name='inviteCode'
          variant='filled'
          className='form-control invite-code-field'
          value={form.values.inviteCode}
          onChange={form.handleChange}
          label={t`InviteCodeFormLabel`}
          InputLabelProps={{ className: 'lato label' }}
        />
      )}
      <Button
        id='continue-btn'
        type='submit'
        className={`btn btn-responsive lato ${isDisabled ? 'disabled' : ''}`}
        disabled={isDisabled}
        style={{
          backgroundColor: customTheme.button,
          color: customTheme.buttonText
        }}
      >
        {t`Continue`}
      </Button>
      <Box className='sign-up-actions'>
        <Typography className='text'>{t`SignUpContinueText`}</Typography>
        <Link
          to={{ pathname: termsAndConditionsUrl }}
          target='_blank'
          aria-label={t`TermsAndConditions`}
        >
          <Typography className='link-text'>{t`TermsAndConditions`}</Typography>
        </Link>
        <Typography className='text'>{t`and`}</Typography>
        <Link
          to={{ pathname: privacyPolicyUrl }}
          target='_blank'
          aria-label={t`PrivacyPolicy`}
        >
          <Typography className='link-text'>{t`PrivacyPolicy`}</Typography>
        </Link>
        <br />
        <Link
          to={{ pathname: moreInformationUrl }}
          target='_blank'
          aria-label='more information'
        >
          <Typography className='link-text'>{moreInformationLink}</Typography>
        </Link>
        <Typography className='text'>
          {moreInformationLinkDescription}
        </Typography>
      </Box>
    </form>
  )
}

export default InviteCodeForm
