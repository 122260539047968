import { UseTranslationResponse } from 'react-i18next'
import {
  regexConsecutiveDot,
  regexDotBeforeAlpha,
  regexInvalidSpecialChars,
  regexName,
  regexPasswordComposition,
  regexEmail,
  emailMaxLength,
  emailMinLength,
  passwordMinLength,
  passwordMaxLength,
  nameMinLength,
  nameMaxLength
} from './constants'
import { ReactElement } from 'react'
import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import CustomSwitch from '../ui/custom-switch/CustomSwitch'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import _ from 'lodash'
import welcomeImage from '../../assets/images/welcome/main.png'

export const validateEmail = (
  value: string,
  errors: { [key: string]: string },
  i18t: UseTranslationResponse<'translation', undefined>
): { [key: string]: string } => {
  const updatedErrors = { ...errors }
  if (value === '') {
    updatedErrors.email = i18t.t`MissingEmailError`
  } else if (
    !regexEmail.test(value) ||
    regexDotBeforeAlpha.test(value) ||
    regexConsecutiveDot.test(value) ||
    value.length > emailMaxLength ||
    value.length < emailMinLength
  ) {
    updatedErrors.email = i18t.t`InvalidEmailError`
  }
  return updatedErrors
}

export const validateName = (
  value: string,
  errors: { [key: string]: string },
  nameWithoutSpaces: string,
  i18t: UseTranslationResponse<'translation', undefined>
): { [key: string]: string } => {
  const updatedErrors = { ...errors }
  if (value === '') {
    updatedErrors.name = i18t.t`MissingNameError`
  } else if (!regexName.test(value)) {
    updatedErrors.name = i18t.t`InvalidNameError`
  } else if (
    nameWithoutSpaces.length < nameMinLength ||
    nameWithoutSpaces.length > nameMaxLength
  ) {
    updatedErrors.name = i18t.t`NameLengthError`
  }
  return updatedErrors
}

export const validatePassword = (
  value: string,
  errors: { [key: string]: string },
  key: string,
  i18t: UseTranslationResponse<'translation', undefined>
): { [key: string]: string } => {
  const updatedErrors = { ...errors }
  if (value === '') {
    updatedErrors[key] = i18t.t`MissingPasswordError`
  } else if (
    value.length < passwordMinLength ||
    value.length > passwordMaxLength
  ) {
    updatedErrors[key] = i18t.t`PasswordLengthError`
  } else if (regexInvalidSpecialChars.test(value)) {
    updatedErrors[key] = i18t.t`InvalidSpecialCharError`
  } else if (!regexPasswordComposition.test(value)) {
    updatedErrors[key] = i18t.t`IncorrectPasswordCompositionError`
  }
  return updatedErrors
}

export const showPasswordToggleIcon = (
  showPassword: boolean,
  setShowPassword: (value: boolean) => void
): ReactElement => {
  return (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </IconButton>
    </InputAdornment>
  )
}

export const optionalInviteCodeToggle = (
  i18t: UseTranslationResponse<'translation', undefined>,
  setToggle: (value: boolean) => void,
  isToggleChecked: boolean
): ReactElement => (
  <Box className='opt-invite-code-toggle'>
    <div>
      <Box className='icon-box'>
        <ConfirmationNumberOutlinedIcon />
      </Box>
    </div>
    <Box className='text-box'>
      <Typography className='title'>{i18t.t`InvitCodeFormTitle`}</Typography>
      <Typography className='helper-text'>
        {i18t.t`InvitCodeFormHelperText`}
      </Typography>
    </Box>
    <CustomSwitch
      onClick={() => setToggle(!isToggleChecked)}
      inputProps={{
        'aria-label': 'invite code status'
      }}
    />
  </Box>
)

export const getSignUpWelcomeSteps = (
  config: ConfigState,
  stepTitleStyle?: React.CSSProperties,
  stepTitleClass?: string,
  stepDescriptionStyle?: React.CSSProperties
): ReactElement[] =>
  config.WelcomeStepsConfig.map((step, index) => {
    return (
      <Box className='step-info' key={`${index}-step`}>
        <div className='step-header'>
          <img src={step.StepImage} alt={step.StepTitle} aria-hidden='true' />
          <div>
            <Typography style={stepTitleStyle} className={stepTitleClass}>
              {step.StepTitle}
            </Typography>
            <Typography className='description' style={stepDescriptionStyle}>
              {step.StepDescription}
            </Typography>
          </div>
        </div>
      </Box>
    )
  })

export const getSignUpScreenImage = (config: ConfigState): string => {
  return !_.isEmpty(config.SignUpScreenImage)
    ? config.SignUpScreenImage
    : welcomeImage
}
