import React, { ReactElement } from 'react'
import '../SignUp.scss'
import { Box, Typography } from '@material-ui/core'
import { getSignUpScreenImage, getSignUpWelcomeSteps } from '../../utils'
import { useSelector } from 'react-redux'
import { selectConfig } from '../../../config/selectors'
import image from '../../../../assets/images/pattern.png'

interface SignUpRightPanelProps {
  customTheme: {[key: string]: string}
}

const SignUpRightPanel = ({ customTheme }: SignUpRightPanelProps): ReactElement => {
  const config: ConfigState = useSelector(selectConfig)

  return (
    <Box
      className='sign-up-right'
      style={{
        backgroundColor: customTheme.rightPanel,
        backgroundImage: `url(${image as string})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPositionY: '100%'
      }}
    >
      <Box className='steps-box'>
        <Box className='welcome-box'>
          <img src={getSignUpScreenImage(config)} alt='welcome' aria-hidden='true' />
          <Typography style={{ color: customTheme.headerText }}>
            {config.SignUpScreenTitle}
          </Typography>
        </Box>
        {getSignUpWelcomeSteps(
          config,
          { color: customTheme.headerText },
          '',
          { color: customTheme.headerText }
        )}
      </Box>
    </Box>
  )
}

export default SignUpRightPanel
