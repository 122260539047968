import React, { FC } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import { ButtonBase, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { IdList } from '../../../core/utils/id-list'
import { breakpoints } from '../../../core/utils/css-selectors'
import BackIco from '../../customIcons/BackIco'

interface CloseBtnProps {
  handleCloseMenu: () => void
  isFromUpdatedDesktopUI?: boolean
}

const CloseBtn: FC<CloseBtnProps> = ({
  handleCloseMenu,
  isFromUpdatedDesktopUI = false
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCloseMenu()
        }
      }}
    >
      {matchesMd && !isFromUpdatedDesktopUI ? (
        <IconButton
          id={IdList.navModalClose}
          onClick={handleCloseMenu}
          className='base-drawer-close'
          aria-label='Close dialog'
        >
          <BackIco />
        </IconButton>
      ) : (
        <ButtonBase
          id={IdList.navModalClose}
          className='base-drawer-close'
          onClick={handleCloseMenu}
          aria-label='Close dialog'
        >
          <CloseIcon />
        </ButtonBase>
      )}
    </div>
  )
}

export default CloseBtn
