import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import TimerIcon from '@material-ui/icons/Timer'
import NumberFormat from 'react-number-format'

import { selectFavoriteOfferCount, selectOfferStatus, selectUser } from '../../../user/selectors'
import { OfferStatus } from '../../offerStatus'
import SoldOut from '../../../ui/SoldOut/SoldOut'
import OfferDrawer from '../OfferDrawer/OfferDrawer'
import BadgeIcon from '../../../ui/customIcons/BadgeIcon/BadgeIcon'
import { getOfferList } from '../../actions'
import { getUserOfferStatus } from '../../../user/actions'
import { UserStatus } from '../../../user/userStatus'
import Locked from '../../../ui/Locked/Locked'
import AvailableMessage from '../../../ui/AvailableMessage/AvailableMessage'
import TransactionHistoryList from '../../../user/TransactionHistoryList/TransactionHistoryList'
import { selectConfig } from '../../../config/selectors'
import RedemptionResultDrawer from '../RedemptionResultDrawer/RedemptionResultDrawer'
import OfferTermsDrawer from '../OfferTermsDrawer/OfferTermsDrawer'

import './OfferListItem.scss'
import placeholderOffer from '../../../../assets/images/offer/placeholder-offer.png'
import { RedemptionType } from '../../redemptionType'
import RedemptionErrorDialog from '../RedemptionErrorDialog/RedemptionErrorDialog'
import OfferListItemUpdated from './OfferListItemUpdated'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import FavoriteOfferItem from '../FavoriteOfferList/FavoriteOfferItem'
import OfferDrawerForFeatureFlag from '../OfferDrawerForFeatureFlag/OfferDrawerForFeatureFlag'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'
interface OfferProps {
  offerItem: Offer
  handleClickLockedOffer: () => void
  showUpdatedUI?: boolean
  isFavoriteOfferAvailable?: boolean
  categoryInfo?: OfferListByCategory
}

export interface RedeemCalc {
  isAvailable: boolean
  difference: number
}

const OfferListItem: FC<OfferProps> = ({
  offerItem,
  handleClickLockedOffer,
  showUpdatedUI = false,
  isFavoriteOfferAvailable = false,
  categoryInfo
}) => {
  const user: UserState = useSelector(selectUser)
  const offerStatus: UserOfferStatus | undefined = useSelector(
    selectOfferStatus(offerItem.OfferID)
  )
  const config: ConfigState = useSelector(selectConfig)
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const [offerRedemption, setOfferRedemption] = useState<
  Redemption | undefined
  >()
  const [isOfferDetailsOpen, setIsOfferDetailsOpen] = useState(false)
  const [isRedemptionResultOpen, setIsRedemptionResultOpen] = useState(false)
  const [isOfferAvailableMessageOpen, setIsOfferAvailableMessageOpen] =
    useState(false)
  const [isOpenTransactionHistory, setIsOpenTransactionHistory] =
    useState<boolean>(false)
  const [isSpinner, setIsSpinner] = useState(false)
  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const dispatch = useDispatch()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const favoriteCount: number = useSelector(selectFavoriteOfferCount)
  const { featureFlags }: UserState = useSelector(selectUser)

  const handleCloseAvailableMessage = (): void => {
    setIsOfferAvailableMessageOpen(false)
  }
  const [redeemCalc, setRedeemCalc] = useState<RedeemCalc>({
    isAvailable: false,
    difference: 0
  })
  useEffect(() => {
    let userBalance = 0

    if (user.Balance !== undefined) {
      userBalance = parseInt(user.Balance)
    }
    setRedeemCalc((prev) => ({
      ...prev,
      isAvailable: userBalance >= offerItem.VeloAmount,
      difference: userBalance - offerItem.VeloAmount
    }))
  }, [user.Balance, offerItem.VeloAmount])

  const handleOpenOffersDetailDrawer = (): void => {
    if (user.userStatus === UserStatus.New || user.userStatus === UserStatus.Consented) {
      handleClickLockedOffer()
      return
    }
    if (offerStatus !== undefined) {
      trackEvent({
        category: 'Offers',
        action: 'Offer Detail View ',
        payload: {
          status: offerStatus.status,
          offerID: offerItem.OfferID
        }
      })

      if (offerStatus.status !== OfferStatus.soldOut) {
        setIsOfferDetailsOpen(true)
      }
    }

    if (user.userStatus === UserStatus.New || user.userStatus === UserStatus.Consented) {
      handleClickLockedOffer()
    }
  }

  const handleTimerIcon = (): void => {
    setIsOfferAvailableMessageOpen(true)

    trackEvent({
      page: AnalyticsCategory.Redemptions,
      action: 'Offer Timer Pressed',
      payload: {
        origin: 'offer card',
        offerID: offerItem.OfferID
      }
    })
  }

  const getImg = (): string => {
    if (offerItem.headerImageUrl != null) {
      return offerItem.headerImageUrl
    }
    return placeholderOffer
  }

  const isOfferLocked = (userStatus: string | undefined): boolean => {
    return (
      userStatus !== undefined &&
      (userStatus === UserStatus.New || userStatus === UserStatus.Consented)
    )
  }

  const isOfferStatusUnavailable = (
    offerStatus: string | undefined
  ): boolean => {
    return (
      offerStatus !== undefined && offerStatus === OfferStatus.unavailableLimit
    )
  }

  const isOfferStatusSoldOut = (offerStatus: string | undefined): boolean => {
    return offerStatus !== undefined && offerStatus === OfferStatus.soldOut
  }

  const isOfferPointsSuccess = (offerStatus: string | undefined): boolean => {
    return (
      redeemCalc.isAvailable &&
      !isOfferStatusUnavailable(offerStatus) &&
      !isOfferStatusSoldOut(offerStatus)
    )
  }

  const isBlocked = (
    userStatus: string | undefined,
    offerStatus: string | undefined
  ): boolean => {
    return (
      isOfferLocked(userStatus) ||
      !redeemCalc.isAvailable ||
      isOfferStatusUnavailable(offerStatus)
    )
  }

  const getPointsField = (): string => {
    if (featureFlags.UX_UPDATES) {
      if (config.WordsForPoints.short != null) {
        return t([config.WordsForPoints.short])
      }
    } else {
      if (config.WordsForPoints.long != null) {
        return t([config.WordsForPoints.long])
      }
    }
    return featureFlags.UX_UPDATES ? t`pts` : t`points`
  }

  const showSpinner = (): void => {
    setIsSpinner(true)
    setIsRedemptionResultOpen(true)
    setIsOfferDetailsOpen(false)
  }

  const handleRedemption = (redemption: Redemption): void => {
    if (offerItem.CouponType === RedemptionType.directExpireTicket) {
      if (redemption.couponUrl !== '') {
        window.open(redemption.couponUrl)
        setIsRedemptionResultOpen(false)
      }
    }
    setOfferRedemption(redemption)
    setIsSpinner(false)
  }

  const handleRedemptionError = (): void => {
    setIsSpinner(true)
    setIsRedemptionResultOpen(false)
    setShowErrorDialog(true)
  }

  const handleCloseRedemption = async (): Promise<void> => {
    setIsSpinner(true)
    setIsRedemptionResultOpen(false)
    setOfferRedemption(undefined)
    setIsSpinner(false)
    await Promise.all([
      dispatch(getOfferList()),
      dispatch(getUserOfferStatus())
    ])
  }

  const handleOpenHistory = async (): Promise<void> => {
    await handleCloseRedemption()
    setIsOpenTransactionHistory(true)
  }

  const handleOpenTerms = (): void => {
    setIsOfferDetailsOpen(false)
    setIsRedemptionResultOpen(false)
    setIsTermsOpen(true)
  }

  let points

  if (offerItem.VeloAmount !== 0) {
    points = (
      <>
        <NumberFormat
          value={offerItem.VeloAmount}
          displayType='text'
          thousandSeparator
        />
        &nbsp;
        {getPointsField()}
      </>
    )
  } else {
    points = <> {t`Free`} </>
  }
  return (
    <>
      {isFavoriteOfferAvailable && categoryInfo != null ? (
        <FavoriteOfferItem
          categoryInfo={categoryInfo}
          handleOfferItemClick={() => handleOpenOffersDetailDrawer()}
          points={points}
          subTitle={offerItem.SubTitle}
          title={offerItem.Title}
          isOfferStatusSoldOut={isOfferStatusSoldOut(offerStatus?.status)}
          isOfferStatusUnavailable={isOfferStatusUnavailable(offerStatus?.status)}
          isBlocked={isBlocked(user.userStatus, offerStatus?.status)}
          handleUnavailableOfferItemClick={handleTimerIcon}
          OfferID={offerItem.OfferID}
          isFavorite={offerItem.isFavorite}
          disableFavorite={favoriteCount >= config.FavoriteOffersLimit}
          redeemCalc={redeemCalc}
        />
      ) : !showUpdatedUI ? (
        <div className='offer__list__item'>
          <div
            className='offer__list__item__preview'
            onClick={() => handleOpenOffersDetailDrawer()}
          >
            {isOfferStatusSoldOut(offerStatus?.status) && <SoldOut />}
            {isBlocked(user.userStatus, offerStatus?.status) && <Locked />}

            <div
              style={{ backgroundImage: `url(${getImg()})` }}
              className='offer__list__item__preview__img'
              role='img'
              aria-label='A preview banner for the offer'
            />

            {isOfferStatusUnavailable(offerStatus?.status) && (
              <BadgeIcon
                icon={TimerIcon}
                clickBtn={handleTimerIcon}
                shadow
                className='badge-top-left--5'
              />
            )}
          </div>

          <span
            className={
              isOfferLocked(user.userStatus)
                ? 'offer__list__item__amount'
                : `offer__list__item__amount${
                    isOfferPointsSuccess(offerStatus?.status) ? '--success' : ''
                  }`
            }
          >
            {points}
          </span>
          <span className='offer__list__item__title'>{offerItem.Title}</span>
        </div>
      ) : (
        <OfferListItemUpdated
          handleOfferItemClick={() => handleOpenOffersDetailDrawer()}
          points={points}
          subTitle={offerItem.SubTitle}
          title={offerItem.Title}
          isOfferStatusSoldOut={isOfferStatusSoldOut(offerStatus?.status)}
          isOfferStatusUnavailable={isOfferStatusUnavailable(offerStatus?.status)}
          isBlocked={isBlocked(user.userStatus, offerStatus?.status)}
          handleUnavailableOfferItemClick={handleTimerIcon}
          isFavoriteOfferAvailable={isFavoriteOfferAvailable}
          OfferID={offerItem.OfferID}
          isFavorite={offerItem.isFavorite}
          redeemCalc={redeemCalc}
          disableFavorite={favoriteCount >= config.FavoriteOffersLimit}
        />
      )}
      {featureFlags.UX_UPDATES ? (
        <OfferDrawerForFeatureFlag
          showSpinner={showSpinner}
          handleRedeemOffer={handleRedemption}
          handleRedeemOfferError={handleRedemptionError}
          isOfferDetailsOpen={isOfferDetailsOpen}
          setIsOfferDetailsOpen={setIsOfferDetailsOpen}
          offerItem={offerItem}
          redeemCalc={redeemCalc}
          handleOpenTerms={handleOpenTerms}
        />
      ) : (
        <OfferDrawer
          showSpinner={showSpinner}
          handleRedeemOffer={handleRedemption}
          handleRedeemOfferError={handleRedemptionError}
          isOfferDetailsOpen={isOfferDetailsOpen}
          setIsOfferDetailsOpen={setIsOfferDetailsOpen}
          offerItem={offerItem}
          redeemCalc={redeemCalc}
          handleOpenTerms={handleOpenTerms}
        />
      )}

      <RedemptionResultDrawer
        isSpinner={isSpinner}
        isRedemptionResultOpen={isRedemptionResultOpen}
        redemption={offerRedemption}
        handleCloseRedemption={handleCloseRedemption}
        offerItem={offerItem}
        handleOpenHistory={handleOpenHistory}
        handleOpenTerms={handleOpenTerms}
        isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
      />

      <RedemptionErrorDialog
        open={showErrorDialog}
        handleClose={() => setShowErrorDialog(false)}
        isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
        offerImage={featureFlags.UX_UPDATES ? getImg() : ''}
        isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
      />

      {offerStatus !== undefined && (
        <AvailableMessage
          isAvailableMessageOpen={isOfferAvailableMessageOpen}
          handleCloseAvailableMessage={handleCloseAvailableMessage}
          nextResetAtUTC={offerStatus.NextResetAtUTC}
        />
      )}
      <TransactionHistoryList
        isOpenTransactionHistory={isOpenTransactionHistory}
        setIsOpenTransactionHistory={setIsOpenTransactionHistory}
        isFromDesktop={matchesMd}
      />

      <OfferTermsDrawer
        isOpen={isTermsOpen}
        setIsOpen={setIsTermsOpen}
        title={offerItem.TermsAndConditionsTitle}
        description={offerItem.TermsAndConditionsBody}
        isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
        isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
      />
    </>
  )
}

export default OfferListItem
