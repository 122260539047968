import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import { CampaignStatusList } from '../campaignStatus'
import { selectCampaignStatus } from '../selectors'
import CampaignIco from '../CampaignIco/CampaignIco'
import DynamicIcon from '../../ui/customIcons/DynamicIcon/DynamicIcon'
import { selectConfig } from '../../config/selectors'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router'
import './CampaignDrawer.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import placeholderCampaign from '../../../assets/images/campaign/placeholder-campaign.png'
import './CampaignDrawerUpdated.scss'
import _ from 'lodash'
import {
  CampaignCtaActions,
  CampaignTypes,
  MAX_BADGE_LIMIT
} from '../CampaignConstants'
import StreakProgressUpdated from '../../ui/Streak/StreakProgressUpdated'
import StreakProgressWithTimeDistinct from '../../ui/Streak/StreakProgressWithTimeDistinct'
import MDEditor from '@uiw/react-md-editor'
import { isValidUrl, isExternal } from '../../utils'
import TierProgress from '../../ui/Tier/TierProgress'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import { RoutePath } from '../../core/routes/route-path'
import OpenProviderDialog from '../../provider/ProviderList/OpenProviderDialog/OpenProviderDialog'
import { getRedirectURLForOAUTH } from '../../provider/actions'
import * as Sentry from '@sentry/react'
import { selectProviderList } from '../../provider/selectors'
import { ConnectionType } from '../../provider/connectionType'
import ArrowForward from '@material-ui/icons/ArrowForwardOutlined'
import ErrorOutline from '@material-ui/icons/ErrorOutlineOutlined'

interface CampaignDrawerUpdatedProps {
  campaignItem: Campaign
  isCampaignDetailsOpen: boolean
  setIsCampaignDetailsOpen: (isCampaignDetailsOpen: boolean) => void
  handleOpenUnavailableMessage: () => void
  campaignProvidersNotConnected: ProviderStatus[]
}

const CampaignDrawerUpdated: FC<CampaignDrawerUpdatedProps> = ({
  campaignItem,
  isCampaignDetailsOpen,
  setIsCampaignDetailsOpen,
  handleOpenUnavailableMessage,
  campaignProvidersNotConnected = []
}) => {
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const campaignStatus: CampaignStatus | undefined = useSelector(
    selectCampaignStatus(campaignItem.CampaignID)
  )
  const providerList: ProviderAccount[] = useSelector(selectProviderList)
  const config: ConfigState = useSelector(selectConfig)
  const history = useHistory()
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false)
  const [updatedCampaign, setUpdatedCampaign] = useState<Campaign>({
    ...campaignItem
  })

  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const showsPts =
    campaignItem.CampaignType === CampaignTypes.STREAK
      ? campaignStatus?.streakLevelRewards > 0
      : campaignItem.CampaignType === CampaignTypes.TIER
        ? campaignStatus?.currentTierPoints > 0
        : campaignItem.RewardPerUser > 0

  useEffect(() => {
    if (campaignStatus !== undefined && isCampaignDetailsOpen) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Detail View',
        payload: {
          status: campaignStatus.status,
          campaignID: campaignItem.CampaignID
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStatus, isCampaignDetailsOpen])

  const handleIconClick = (): void => {
    if (campaignStatus?.status === CampaignStatusList.TimeLimited) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Timer Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })

      handleOpenUnavailableMessage()
    } else if (campaignStatus?.status === CampaignStatusList.Locked) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Lock Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })
    }
  }

  const isActionBtn = (): boolean =>
    (campaignStatus?.status === CampaignStatusList.Available &&
      Boolean(campaignItem.ActionButtonUrl)) ||
    campaignItem.CampaignType === CampaignTypes.CONNECT

  const getPointsField = (): string => {
    if (config.WordsForPoints.short != null) {
      return t([config.WordsForPoints.short])
    }
    return t`pts`
  }

  const getPoints = (): string | undefined => {
    if (showsPts) {
      return `${
        campaignItem.CampaignType === CampaignTypes.STREAK
          ? +campaignStatus?.streakLevelRewards
          : campaignItem?.CampaignType === CampaignTypes.TIER
          ? +campaignStatus?.currentTierPoints
          : campaignItem.RewardPerUser
      } ${getPointsField()}`
    }
  }
  const trackCTAClick = (campaignItem: Campaign): void => {
    trackEvent({
      action: 'Campaign Detail CTA',
      category: AnalyticsCategory.Campaigns,
      payload: {
        title: campaignItem.Title,
        description: campaignItem.Description,
        campaignID: campaignItem.CampaignID
      }
    })
  }

  const checkProviderAndOpen = useCallback(
    (ProviderID: string): void => {
      const targetProvider = _.find(providerList, {
        ProviderID
      })
      if (
        targetProvider?.connectionType === ConnectionType.directOauth &&
        targetProvider?.IsPKCEFlow
      ) {
        dispatch(getRedirectURLForOAUTH(ProviderID))
          .then(() => setIsConnectDialogOpen(true))
          .catch((error: Error) => Sentry.captureException(error))
      } else {
        if (targetProvider?.DisableUserControl === false) {
          setIsConnectDialogOpen(true)
        }
      }
    },
    [providerList, dispatch, setIsConnectDialogOpen]
  )

  const campaignButtonClick = (event: any = null): void => {
    if (event != null) {
      event.preventDefault()
    }
    setIsCampaignDetailsOpen(false)
    if (campaignItem.CampaignType === CampaignTypes.CONNECT) {
      if (campaignItem.ctaAction === CampaignCtaActions.OPEN) {
        checkProviderAndOpen(campaignItem.providers[0])
      } else if (campaignItem.ctaAction === CampaignCtaActions.REDIRECT) {
        history.push(RoutePath.ConnectAccount)
      }
    } else {
      if (isValidUrl(campaignItem.ActionButtonUrl)) {
        const external = isExternal(campaignItem.ActionButtonUrl)
        if (external) {
          window.open(campaignItem.ActionButtonUrl)
        } else {
          history.push(campaignItem.ActionButtonUrl)
        }
      }
      trackCTAClick(campaignItem)
    }
  }

  const isAllCampaignProvidersConnected =
    campaignProvidersNotConnected.length < 1

  const handleProviderConnect = useCallback(
    (providerID: string): void => {
      setUpdatedCampaign((prevCampaign) => ({
        ...prevCampaign,
        providers: [providerID]
      }))
      checkProviderAndOpen(providerID)
    },
    [setUpdatedCampaign, checkProviderAndOpen]
  )

  const isSurveyRequiredProvidersConnected = (): boolean =>
    updatedCampaign.CampaignType !== CampaignTypes.EXTERNAL_SURVEY ||
    (updatedCampaign.CampaignType === CampaignTypes.EXTERNAL_SURVEY &&
      isAllCampaignProvidersConnected)

  const getImg = (): string => {
    if (campaignItem?.headerImageUrl != null) {
      return campaignItem.headerImageUrl
    }
    return placeholderCampaign
  }
  const isUnavailable =
    campaignStatus?.status === CampaignStatusList.TimeLimited ||
    campaignStatus?.status === CampaignStatusList.Locked

  return (
    <>
      <BaseDrawer
        isOpen={isCampaignDetailsOpen}
        onClose={() => setIsCampaignDetailsOpen(false)}
        customMargin={matchesMd ? '20px' : ''}
        isFromUpdatedUI={!matchesMd}
        isFromUpdatedDesktopUI={matchesMd}
      >
        <div
          className={`campaign-drawer-updated ${matchesMd ? 'maxh-75' : ''}`}
        >
          <div
            className={`campaign-drawer-img ${isUnavailable ? 'locked' : ''} ${
              matchesMd ? 'updated-img' : ''
            }`}
            style={{ backgroundImage: `url(${getImg()})` }}
            role='img'
            aria-label='A promotional banner for the campaign'
          />
          <div
            className={`campaign-drawer__status-ico ${
              matchesMd ? 'icon-updated' : ''
            }`}
          >
            {campaignStatus !== undefined && (
              <CampaignIco
                campaignStatus={campaignStatus}
                handleBadge={handleIconClick}
                campaignLocked={
                  campaignStatus?.status === CampaignStatusList.Locked
                }
              />
            )}
          </div>

          <div className={`campaign-drawer-details ${matchesMd ? 'p-2' : ''}`}>
            {!_.isEmpty(campaignItem.SubHeader) && (
              <div className='subheader-1'>{campaignItem.SubHeader}</div>
            )}

            <div>
              <span className='title'>{campaignItem.Title}</span>
            </div>

            {campaignItem.badges.length > 0 && (
              <div className='badges-container'>
                {campaignItem?.badges.map(
                  (badge, i) =>
                    i < MAX_BADGE_LIMIT && (
                      <span
                        className='campaign-drawer__timer'
                        key={`badge-${i}`}
                      >
                        <DynamicIcon
                          iconName={badge.BadgeIcon}
                          className='icon__ico m-right-3'
                        />
                        <span>{badge.BadgeLabel}</span>
                      </span>
                    )
                )}
              </div>
            )}

            {showsPts && (
              <div
                className={`points ${isUnavailable ? 'locked-background' : ''}`}
              >
                {getPoints()}
              </div>
            )}

            <div
              className={`description ${
                matchesMd &&
                !isUnavailable &&
                campaignItem?.CampaignType !== CampaignTypes.TIER
                  ? 'description-updated'
                  : ''
              }`}
            >
              <MDEditor.Markdown
                source={campaignItem.Description}
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>
            {campaignItem?.CampaignType === CampaignTypes.STREAK &&
              campaignStatus != null &&
              isAllCampaignProvidersConnected &&
              (
                <>
                  {campaignStatus?.hasTimeDistinct ? (
                    <StreakProgressWithTimeDistinct
                      currentLevel={campaignStatus?.streakCurrentLevel}
                      totalLevel={campaignStatus?.streakTotalLevels}
                      daysLeft={campaignStatus?.streakDaysLeft}
                      completed={campaignStatus?.streakCompleted}
                      streakTarget={campaignStatus?.streakTarget}
                      behavioursCount={
                        campaignStatus?.behavioursCountInTimeDistinct
                      }
                      timeDistinctCount={campaignStatus?.timeDistinctCount}
                      timeDistinctUnit={campaignStatus?.timeDistinctUnit}
                    />
                  ) : (
                    <StreakProgressUpdated
                      trips={campaignStatus?.progress}
                      completion={campaignStatus?.streakCurrentLevelCompletion}
                      currentLevel={campaignStatus?.streakCurrentLevel}
                      totalLevel={campaignStatus?.streakTotalLevels}
                      daysLeft={campaignStatus?.streakDaysLeft}
                      completed={campaignStatus?.streakCompleted}
                      streakLevelTarget={campaignStatus?.streakTarget}
                      isFromDetailView
                    />
                  )}
                </>
              )}
            {campaignItem?.CampaignType === CampaignTypes.TIER &&
              campaignStatus != null &&
              isAllCampaignProvidersConnected &&
              (
                <div
                  className={`${
                    !_.isEmpty(campaignItem.SubTitle)
                      ? matchesMd
                        ? 'mt-1'
                        : 'mt-05'
                      : ''
                  } ${isUnavailable ? 'mb-1' : ''}`}
                >
                  <TierProgress
                    tierProperties={campaignStatus?.tierPropertiesForCampaign}
                    tierProgress={campaignStatus?.tierProgress}
                    tierType={campaignStatus?.tierType}
                    isDetailView
                    isLastTierCompleted={campaignStatus?.isLastTierCompleted}
                  />
                </div>
              )}
            {isUnavailable && (
              <div className='unavailable-campaign-text'>
                {campaignStatus?.status === CampaignStatusList.Locked ? (
                  t`RequiresAppConnect`
                ) : campaignStatus?.status ===
                  CampaignStatusList.TimeLimited ? (
                    <div>
                      {t`CampaignUnavailable`}
                      <p className='info'>{t`ReachedEarningLimit`}</p>
                    </div>
                  ) : null}
              </div>
            )}
          </div>

          {isActionBtn() && isSurveyRequiredProvidersConnected() && (
            <div className={`cta ${matchesMd ? 'cta-updated' : ''}`}>
              <Button
                onClick={campaignButtonClick}
                onTouchEnd={campaignButtonClick}
                className='btn'
              >
                {campaignItem.ActionButtonLabel}
              </Button>
            </div>
          )}
          {campaignProvidersNotConnected.length > 0 && (
            <div className='action-section'>
              <div className='action-title'>
                <span>{t`ConnectActionRequire`}</span>
              </div>
              {campaignProvidersNotConnected.map((provider) => {
                const matchedProvider = providerList.find(
                  (p) => p.ProviderID === provider.ProviderID
                )
                const providerName =
                  matchedProvider?.translations?.fullName ?? provider.ProviderID

                return (
                  <div
                    tabIndex={0}
                    onClick={() => handleProviderConnect(provider.ProviderID)}
                    className='action-info'
                    key={provider.ProviderID}
                  >
                    <div className='left-section'>
                      <ErrorOutline />
                      {`${t`Connect`} ${providerName}`}
                    </div>
                    <div className='right-section'>
                      <ArrowForward />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </BaseDrawer>
      <OpenProviderDialog
        setIsConnectDialogOpen={setIsConnectDialogOpen}
        isConnectDialogOpen={isConnectDialogOpen}
        campaignItem={updatedCampaign}
      />
    </>
  )
}

export default CampaignDrawerUpdated
